import { LEOThemeConfig } from "./LEOTheme/utils/type-utils";
export const leoThemeConfig: LEOThemeConfig = {
  disableProfilePictures: false,
  maskDatadogActions: true,
  appMetaData: {
    appTitle: "iSearch",
    contactName: "Martin Iglsoe Andersen",
    contactEmail: "zdtdk@leo-pharma.com",
    byline: "Developed by R&D Data & Analytics",
  },
};
